/* 
* ESKER SCSS 
*/

@use "@styles/cl/core/colors";

.md-esker-ui {
  $font-family-sans-serif:
    "Open Sans",
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji" !default;

  font-family: $font-family-sans-serif;

  --bs-primary-color: #{colors.$md-primary};
  --bs-secondary-color: #212529bf;

  --md-status-closed-color: #98a3ad;
  --md-status-draft-color: #f6b221;

  /*
  * Input colours, from app/assets/stylesheets/cl/core/_colors.scss
  */

  --md-input-border: #{colors.$md-input-border};
  --md-input-border-hover: #{colors.$md-input-border-hover};
  --md-input-border-focus: #{colors.$md-input-border-focus};
  --md-input-border-error: #{colors.$md-input-border-error};
  --md-input-border-disabled: #{colors.$md-input-border-disabled};
  --md-input-background: #{colors.$md-input-background};
  --md-input-background-disabled: #{colors.$md-input-background-disabled};
  --md-input-text: #{colors.$md-input-text};
  --md-input-text-disabled: #{colors.$md-input-text-disabled};

  /*
  * TABS
  */

  ul.nav {
    --bs-nav-pills-border-radius: 0;
    --bs-nav-pills-link-color: #8899ab;
    --bs-nav-pills-link-active-color: #{colors.$md-primary};
    --bs-nav-pills-link-active-bg: white;
    --bs-nav-link-padding-x: 0.7rem;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: #8899ab;
    --bs-nav-link-hover-color: #{colors.$md-primary};
    --bs-nav-link-disabled-color: var(--bs-secondary-color);
    --bs-nav-link-font-size: 14px;
    --bs-nav-link-font-weight: bold;

    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg);

    // display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    li {
      &.active {
        border-bottom: 0.15rem solid colors.$md-primary;

        a {
          color: var(--bs-nav-pills-link-active-color) !important;
        }
      }
      a {
        color: var(--bs-nav-pills-link-color) !important;
        background-color: var(--bs-nav-pills-link-active-bg) !important;
        border-radius: var(--bs-nav-pills-border-radius) !important;
        cursor: pointer;
        font-weight: var(--bs-nav-link-font-weight);
        font-size: var(--bs-nav-link-font-size);
        display: block;
        padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x) !important;

        text-decoration: none;
        background: none !important;
        border: 0 !important;
        height: inherit !important;
        transition:
          color 0.15s ease-in-out,
          background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out;

        &.white-text {
          color: white !important;
        }
      }
    }
  }

  /*
  * OVERWRITING
  */

  span.tab-line {
    display: none !important;
  }
  .special-tab-content.tab-status-current .nav.nav-tabs li.active > a,
  .special-tab-content.tab-status-draft .nav.nav-tabs li.active > a,
  .special-tab-content .nav.nav-tabs li.active > a {
    color: var(--bs-primary-color) !important;
    top: 0 !important;
  }

  .btn {
    &.btn-primary {
      color: white !important;
      background-color: var(--bs-primary-color);
      font-weight: 700;
      font-size: 1rem;
      border-radius: 0.56rem;
      line-height: 20px;
      &:not(:focus-visible) {
        box-shadow: none;
      }
    }
    &:not(.btn-md):not(.shadow-sm):not(.multiselect):not(.btn-icon):not(.expand-button):not(.dropdown-toggle):not(
        [data-toggle="modal"]
      ) {
      color: var(--bs-primary-color);
      --bs-btn-hover-color: white;
      --bs-btn-color: var(--bs-primary-color);
      border-color: var(--bs-primary-color);
      font-weight: 700;
      font-size: 1rem;
      border-radius: 0.56rem;
      padding: 0.656rem 0.875rem;
      line-height: 20px;
      height: 40px;
    }
    &-outlined-primary:hover {
      color: white !important;
    }
    &.btn-danger {
      color: white !important;
    }
    &.btn-prominent.status-event.draft {
      color: white !important;
    }
    &.btn-secondary.save-btn,
    .btn-default:active,
    .btn-default.active {
      border-color: var(--bs-primary-color);
      background: #fff;
      color: var(--bs-primary-color);
    }
  }

  a:not(.btn):not(.button):not(.btn-icon):not(.button-label):not(.dropdown-toggle):not(.purple):not(.status-event):not(
      .cke_button
    ):not(.cke_dialog_tab):not(.cke_dialog_ui_button):not(.cke_combo_button):not(.cke_dialog_close_button):not(
      .red-color
    ):not(.tagit-close):not(.link):not(.esker-navbar-link) {
    color: var(--bs-primary-color);
  }

  .filter-block .btn i,
  .form-item-block .datetime-input.new:after,
  .form-item-block .datetime-input:after,
  .blue-color,
  .small-gray-box a,
  .red-color,
  .event-list .btn.btn-default .fa.fa-envelope,
  i.fa.fa-pencil,
  i.fa.fa-trash,
  i.fa-comment,
  i.fa-floppy-o,
  i.fa.fa-trash-o:hover {
    color: var(--bs-primary-color) !important;
  }

  .score-onhover {
    .btn-icon {
      border-color: var(--bs-primary-color);
      color: var(--bs-primary-color);
      &.save-btn {
        background-color: var(--bs-primary-color) !important;
        i {
          color: white !important;
        }
      }
    }
  }

  .btn {
    i.fa.fa-pencil,
    i.fa.fa-trash,
    i.fa.fa-trash-o:hover {
      color: inherit !important;
    }
  }

  .label-primary,
  .btn-prominent.draft,
  .btn-prominent.new,
  .xdsoft_datetimepicker .xdsoft_calendar table td.xdsoft_current,
  table
    tbody
    a:not(.btn):not(.button):not(.btn-icon):not(.button-label):not(.dropdown-toggle):not(.purple):not(
      .status-event
    ):not(.cke_button):not(.cke_dialog_tab):not(.cke_dialog_ui_button):not(.cke_combo_button):not(
      .cke_dialog_close_button
    ):not(.red-color):not(.tagit-close):not(.link):hover,
  .btn-prominent.current,
  span.label-info,
  .bg-red {
    background-color: var(--bs-primary-color) !important;
  }

  .blue-bordered-btn,
  .active-supplier-filter .select2-container--default .select2-selection--single {
    border-color: var(--bs-primary-color);
  }

  .btn-blue-outline,
  .rbc-btn-group button,
  .btn-default.new,
  .status-event.draft,
  .status-event.uploaded-here,
  .status-event.invited,
  .status-event.not-downloaded,
  .status-event.closed,
  .draft.btn-default,
  .btn-default.edit,
  .btn-default:active,
  .btn-default.active,
  .btn.btn-draft-outline,
  .btn-action.blue-outline .btn,
  .btn-action.blue-outline .btn:hover,
  .btn-blue-outline:hover {
    color: var(--bs-primary-color) !important;
    border-color: var(--bs-primary-color) !important;
  }

  .api-key-actions a.btn.btn-success,
  #twoFactorAuthentication a.btn.btn-success {
    border-color: var(--bs-primary-color);
    background: #fff;
    color: var(--bs-primary-color);
  }

  a.btn-secondary.save-btn:hover,
  .api-key-actions a.btn.btn-success:hover,
  #twoFactorAuthentication a.btn.btn-success:hover {
    color: white !important;
    background-color: colors.$md-primary;
    border-color: colors.$md-primary !important;
  }

  .rbc-btn-group button.rbc-active,
  .rbc-btn-group button:focus,
  .rbc-btn-group button:hover,
  .pagination > .active > span,
  .pagination > .active > span:hover {
    background: var(--bs-primary-color);
    border-color: var(--bs-primary-color);
    color: white !important;
  }

  .status-event.current {
    border-color: var(--bs-primary-color);
    color: var(--bs-primary-color);
    a.button-label {
      color: var(--bs-primary-color);
    }
  }

  span.select2-selection {
    border-color: var(--bs-primary-color) !important;
    span.select2-selection__rendered {
      color: var(--bs-primary-color) !important;
    }
    span.select2-selection__arrow b {
      border-color: var(--bs-primary-color) transparent transparent transparent !important;
    }
  }

  .event-list table thead td .search-button,
  .btn-group-sm .btn,
  #notes_filter .form-group .btn {
    height: auto;

    i.fa {
      margin-left: 0;
    }
  }

  .event-list .table-block-title td i.fa-refresh {
    line-height: normal;
  }

  .inputfile-6 + label,
  .inputfile-6 + label:hover,
  .inputfile-6 + label:focus-visible {
    color: var(--bs-primary-color);
    border-color: var(--bs-primary-color);
    strong {
      background-color: var(--bs-primary-color);
    }
  }

  .lot-button-container {
    button.auto_width_btn.btn.btn-prominent.draft.shadow-diffuse.status-event {
      color: white !important;
    }
  }

  // MD-T3926 - AL Participant View; eye icon off-centre
  .container-fluid .table .cell-number a.btn.btn-default.btn-xs.small-btn.small-blue-btn {
    padding: 0;
    height: 25px;
  }

  // MD-T3926 - Advanced Lot Creation; clashing blue/green UI
  div#formula_fields {
    div.lot-formula-scroll span.btn.btn-default[draggable="true"],
    div#lineItemNumbers span.btn.btn-default.value,
    div#lineItemOperators span.btn.btn-default.value,
    div#lineItemOperators span[data-name="clear-formula"] i.fa.fa-trash,
    div#lineItemOperators span.autosum.btn {
      color: white !important;
    }

    div.lot-formula-scroll span.btn.btn-default[draggable="true"] {
      background-color: #85d17d;
    }

    // Hover
    div#lineItemNumbers span.btn.btn-default.value:hover,
    div#lineItemOperators span.btn.btn-default.value:not(.clear-formula):hover,
    div#lineItemOperators span.autosum.btn:hover {
      background-color: #8fd987;
    }

    // AdvLot, removing ugly border from the footnote info (Calculation Row Cell, Lot Total Cell, Price Cell)
    div.notifiers div.form-group i.btn {
      border: none;
    }
  }

  ul.nav.nav-tabs li.tab-item i.fa.fa-times.red-color,
  table.participant-database-table div i.fa.fa-times.red-color {
    color: #ed4d4d !important;
  }

  div.rbc-calendar .calendar-current-event {
    background-color: var(--bs-primary-color) !important;
  }

  form#new_bid input.btn.btn-danger[type="submit"] {
    border-color: #953629;
  }

  form#new_bid input.btn.btn-danger[type="submit"],
  div.api-key-actions a.btn.btn-danger,
  div.user-integration-attributes a.btn.btn-danger {
    border-color: #953629 !important;
  }

  div.lot-work-row div.tbody-newbid {
    button[type="submit"][data-type="simple_lot"],
    a.br-red-color.btn.btn-default {
      line-height: 20px !important;

      i.fa.fa-times.red-color {
        color: #ed4d4d !important;
      }
    }
  }

  // MD-T3926 - Host Permissions; No Licence in teal not red
  // MD-T3926 - Event Participants; negative labels in teal not red
  div#btabs-static-host-permissions,
  tr.participant td.participant-status {
    div.red-color {
      color: #ed4d4d !important;
    }
  }

  // MD-T3926 - Sourcing Dashboard calendar; event pop-up buttons unreadable
  div[data-widget-name="calendar"] div.modal-footer {
    a.btn.btn-secondary.modal-btn-close,
    button.btn.btn-secondary.modal-btn-close {
      background-color: var(--bs-btn-active-color);

      &:hover {
        background-color: colors.$md-primary;
        color: var(--bs-btn-active-color) !important;
      }
    }
  }

  // MD-T3926 - Time-zone pop-up; Confirm button unreadable
  div.time_zone_pop div.modal-content a.btn.btn-success {
    color: var(--bs-btn-hover-color) !important;
    background-color: var(--bs-primary-color);
  }

  // MD-T3926 - Advanced Lot, Proxy Bid popup, the tick boxes are quite bulbous
  table div.bid-input span.input-group-btn {
    div.btn,
    button.btn {
      padding: 0 8px !important;
      height: 30px !important;
      line-height: 29px !important;
    }
  }

  // MD-T3926 - In Questionnaire section when hover on delete button icon not visible
  div.delete_questionnaire a.btn-outlined-primary:hover {
    i.fa-trash-o.red-color {
      color: white !important;
    }
  }

  // MD-T3926 - Dropdown Status colour in List Events page and Event page
  div.button-event-closed button.status-event {
    color: var(--md-status-closed-color) !important;
    border-color: var(--md-status-closed-color) !important;
  }
  div.button-event-draft button.status-event {
    color: var(--md-status-draft-color) !important;
    border-color: var(--md-status-draft-color) !important;
  }

  // MD-T3926 - Sourcing, Document tab, Upload Section
  form#update_document {
    div.btn.btn-default.btn-file,
    button.btn.btn-default.fileinput-remove-button {
      margin-right: 0;
    }
  }

  // MD-T3926 - All input fields border should be style according to Figma design,
  // https://www.figma.com/design/KmoPJxUJ3K0kbVljrbYvvS/Sourcing-Dojo---Esker-Redesign?node-id=1864-2844&t=GegNJiswmeH5jjvk-0
  input[type="text"]:not(.ui-widget-content),
  input[type="number"],
  input[type="password"],
  input[type="email"],
  textarea,
  button.dropdown-toggle,
  span.select2-selection,
  select.form-control,
  div.form-control.file-caption,
  trix-editor,
  ul.tagit {
    background-color: var(--md-input-background);
    border-color: var(--md-input-border) !important;
    color: var(--md-input-text) !important;

    &:hover {
      border-color: var(--md-input-border-hover) !important;
    }
    &:focus {
      border-color: var(--md-input-border-focus) !important;
      box-shadow: none !important;
    }
    &:disabled {
      background-color: var(--md-input-background-disabled);
      border-color: var(--md-input-border-disabled) !important;
      color: var(--md-input-text-disabled) !important;
    }

    span.select2-selection__rendered {
      color: var(--md-input-text) !important;
    }
  }

  .m-t1 {
    margin-top: 0 !important;
  }
} // .md-esker-ui
